<template lang="pug">
div
  .spinner-container.floor-loader(v-if="loading")
    q-spinner(color="primary", size="3em")
  div(v-show="!loading && image_url")
    .floor-plan-thumb
      .floor-plan-thumb-wrapper
        img.floor-plan-thumb-img(:src="image_url")
        div(v-for="item in dots", :key="item.id")
          FloorPoint(
            :dotData="{ dot: item, editable: false, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id }"
          )
      .floor-plan-thumb-buttons
        q-btn(v-if="canEditPlan", dense, flat, icon="edit", @click="openEditor")
        q-btn(v-if="canResetPlan", dense, flat, icon="close", @click="clearPlan")
        q-btn(v-if="!editable", dense, flat, icon="fas fa-arrows-alt", @click="openFullMap")

  div(v-if="!loading && !image_url")
    .floor-plan-plug
      p Выберите этаж

  q-dialog(
    ref="editor",
    v-model="editor",
    transition-show="slide-left",
    transition-hide="slide-right",
    @before-hide="hideCreateDot"
  )
    q-card.floor-map-dialog-card
      q-card-section
        .floo-map-toolbar-wrapper(v-if="hasCurrent()", v-for="dot in dots", :key="dot.id")
          ToolbarComponent(v-if="dot.current", :dot="dot", @remove="removeDot(dot)")
        div(:class="`floor-plan-editor ${mapHeight}`")
          q-btn(
            dense,
            v-bind:class="changePosition ? 'floor-plan-editor-move active' : 'floor-plan-editor-move'",
            flat,
            icon="fas fa-arrows-alt",
            @click="setChangePosition"
          )
          .floor-plan-editor-buttons
            q-btn(dense, :disable="!plusActive", label="+", flat, @click="zoomPlus")
            q-btn(dense, :disable="!minusActive", label="-", flat, @click="zoomMinus")
          .floor-plan-editor-wrapper(
            :style="{ left: '-' + wrapperPosition.x + 'px', top: '-' + wrapperPosition.y + 'px' }"
          )
            img(
              :style="{ width: imageProps.width + 'px', height: imageProps.height + 'px' }",
              :class="changePosition ? 'floor-plan-editor-img move' : 'floor-plan-editor-img'",
              :src="image_url",
              @mousedown="!changePosition ? createDot() : startChangePosition()"
            )
            div(v-for="item in dots", :key="item.id")
              FloorPoint(
                ref="floorPoint",
                @remove="removeDot(item)",
                @hideCreate="hideCreateDot",
                @setDot="setDot",
                @setCurrent="setCurrent",
                :dotData="{ dot: item, editable: true, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id }",
                :current="item.current"
              )
        LegendComponent(:legendData="{ editable: editable, types: types, icons: icons }")

  q-dialog.floor-map-modal(
    ref="fullMap",
    v-model="fullMap",
    transition-show="slide-left",
    transition-hide="slide-right"
  )
    q-card.floor-map-dialog-card
      q-card-section
        div(:class="`floor-plan-editor ${mapHeight}`")
          .floor-plan-editor-buttons
            q-btn(dense, label="+", flat, @click="zoomPlus")
            q-btn(dense, label="-", flat, @click="zoomMinus")
          .floor-plan-editor-wrapper(
            v-bind:style="{ left: '-' + wrapperPosition.x + 'px', top: '-' + wrapperPosition.y + 'px' }"
          )
            img(
              v-bind:class="changePosition ? 'floor-plan-editor-img move' : 'floor-plan-editor-img'",
              :src="image_url",
              v-bind:style="{ width: imageProps.width + 'px', height: imageProps.height + 'px' }",
              @mousedown="!changePosition ? null : startChangePosition()"
            )
            div(v-for="item in dots", :key="item.id")
              FloorPoint(
                :dotData="{ dot: item, editable: false, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id }"
              )
        LegendComponent(:legendData="{ editable: editable, types: types, icons: icons }")
</template>

<script>
import FloorPoint from "../view/floor_map/floor_point.vue";
import LegendComponent from "../view/floor_map/map/legend.vue";
import ToolbarComponent from "../view/floor_map/map/toolbar.vue";

export default {
  components: {
    FloorPoint,
    LegendComponent,
    ToolbarComponent,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image_url: "",
      dots: [],
      editable: this.parentData.editable,
      floor_id: this.parentData.floor_id,
      building_id: this.parentData.building_id,
      floor_plan_id: this.parentData.floor_plan_id,
      canEdit: this.parentData.canEdit || false,
      canReset: this.parentData.canReset || false,
      editor: false,
      fullMap: false,
      loading: false,
      types: {},
      icons: {},
      imageProps: {
        minWidth: 900,
        width: Number,
        height: Number,
        zoomSteepsWidth: {},
        zoomSteepsHeight: {},
      },
      wrapperPosition: {
        minX: 0,
        minY: 0,
        x: Number,
        y: Number,
      },
      plusActive: true,
      minusActive: true,
      currentZoomSteep: Number,
      changePosition: false,
      startPositionX: Number,
      startPositionY: Number,
      mapHeight: "",
    };
  },

  computed: {
    canEditPlan() {
      return this.editable && this.canEdit;
    },
    canResetPlan() {
      return this.editable && this.canReset;
    },
  },

  watch: {
    parentData: function (newVal, oldVal) {
      // watch it
      this.getMap(newVal.floor_id);
    },
  },

  created() {
    this.getMap(this.floor_id);
    if (this.editable === false) {
      this.changePosition = true;
    }
  },

  methods: {
    openEditor() {
      this.editor = true;
    },

    openFullMap() {
      this.fullMap = true;
    },

    clearPlan() {
      this.$emit("clear");
    },

    createDot() {
      const e = event;
      if (this.dots.find(dot => dot.create === true) || this.dots.find(dot => dot.edit === true)) {
        return;
      }
      const img = e.target;
      const imgWidth = img.width;
      const imgHeight = img.height;
      const round = function (number) {
        return Math.round(parseFloat(number) * 1000) / 10;
      };
      const x = round(e.offsetX / imgWidth);
      const y = round(e.offsetY / imgHeight);
      this.dots.push({
        x: x,
        y: y,
        create: true,
      });
    },

    removeDot(dot) {
      this.$backend.destroy(`/api/v3/plan_points`, dot.id).then(res => {
        this.dots = this.dots.filter(item => item.id !== dot.id);
      });
    },

    setDot(dot) {
      this.hideCreateDot();
      this.dots.push(dot);
    },

    setCurrent(dot) {
      this.dots = this.dots.map(item => {
        if (item.id !== dot.id) {
          item.current = false;
        } else {
          item.current = true;
        }
        return item;
      });
    },

    hasCurrent() {
      return this.dots.find(dot => dot.current === true);
    },

    updateDotFromToolbar(dot) {
      this.dots = this.dots.map(item => {
        if (item.id === dot.id) {
          item = dot;
        }
        return item;
      });
    },

    getDots() {
      this.$backend.index(`/api/v3/plan_points?floor_plan_id=${this.floor_plan_id}`).then(resDots => {
        const dots = [];
        resDots.data.forEach(item => {
          const dot = {
            value: item.device_id,
            type: item.type_device,
            x: item.x,
            y: item.y,
            id: item.id,
            short_info: item.short_info,
            icon_url: item.icon_url,
            figure_type: item.figure_type,
            rotate: item.rotate,
            border_width: item.border_width,
            color: item.color,
          };
          if (item.type_device === "rooms") {
            dot["width"] = item.width;
            dot["height"] = item.height;
          }
          dots.push(dot);
        });
        this.dots = dots;
        this.loading = false;
      });
    },

    hideCreateDot() {
      this.dots = this.dots.filter(item => !item.create);
    },

    getMap(floorId) {
      this.loading = true;
      this.$backend
        .index(`/api/v3/floor_plans?floor_id=${floorId}`)
        .then(resPlan => {
          this.image_url = resPlan.data.plan;
          this.types = resPlan.data.device_types;
          this.icons = resPlan.data.device_icons;
          const img = new Image();
          img.src = this.image_url;
          img.onload = e => {
            const el = e.target;
            this.imageProps["maxWidth"] = el.width;
            this.imageProps["maxHeight"] = el.height;
            this.imageProps["minHeight"] =
              this.imageProps.minWidth * (this.imageProps.maxHeight / this.imageProps.maxWidth);
            const steepWidth = (this.imageProps.maxWidth - this.imageProps.minWidth) / 4;
            for (let i = 0; i <= 4; i++) {
              this.imageProps.zoomSteepsWidth[i] = this.imageProps.minWidth + steepWidth * i;
            }
            const steepHeight = (this.imageProps.maxHeight - this.imageProps.minHeight) / 4;
            for (let i = 0; i <= 4; i++) {
              this.imageProps.zoomSteepsHeight[i] = this.imageProps.minHeight + steepHeight * i;
            }
            this.currentZoomSteep = 2;
            this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep];
            this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep];
            if (el.height < 500) {
              this.mapHeight = "low-height";
            }
            this.getMapCenterPosition();
          };
          this.floor_plan_id = resPlan.data.id;
          this.getDots();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    getMapCenterPosition() {
      let x, y;
      if (this.imageProps.width >= this.imageProps.minWidth) {
        x = (this.imageProps.width - 900) / 2;
        this.wrapperPosition.x = x;
        this.wrapperPosition["maxX"] = x * 2;
      }
      if (this.imageProps.height >= this.imageProps.minHeight) {
        y = (this.imageProps.height - 500) / 2;
        this.wrapperPosition.y = y;
        this.wrapperPosition["maxY"] = y * 2;
      }
    },

    zoomPlus() {
      if (this.currentZoomSteep !== 4) {
        this.currentZoomSteep++;
        this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep];
        this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep];
        this.getMapCenterPosition();
        this.minusActive = true;
      }
      if (this.currentZoomSteep === 4) {
        this.plusActive = false;
      }
    },

    zoomMinus() {
      if (this.currentZoomSteep !== 0) {
        this.currentZoomSteep--;
        this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep];
        this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep];
        this.getMapCenterPosition();
        this.plusActive = true;
      }
      if (this.currentZoomSteep === 0) {
        this.minusActive = false;
      }
    },

    setChangePosition() {
      if (this.changePosition === false) {
        this.changePosition = true;
      } else {
        this.changePosition = false;
      }
    },

    startChangePosition() {
      event.preventDefault();
      this.startPositionX = event.x;
      this.startPositionY = event.y;
      document.addEventListener("mousemove", this.changeMapPosition);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", this.changeMapPosition);
      });
    },

    changeMapPosition(e) {
      const newX = e.x;
      const newY = e.y;
      if (this.wrapperPosition.maxX >= this.wrapperPosition.x && this.wrapperPosition.minX <= this.wrapperPosition.x) {
        this.wrapperPosition.x = this.wrapperPosition.x + this.startPositionX - newX;
      }
      if (this.wrapperPosition.maxY >= this.wrapperPosition.y && this.wrapperPosition.minY <= this.wrapperPosition.y) {
        this.wrapperPosition.y = this.wrapperPosition.y + this.startPositionY - newY;
      }
      if (this.wrapperPosition.maxX <= this.wrapperPosition.x) {
        this.wrapperPosition.x = this.wrapperPosition.maxX;
      }
      if (this.wrapperPosition.minX >= this.wrapperPosition.x) {
        this.wrapperPosition.x = this.wrapperPosition.minX;
      }
      if (this.wrapperPosition.maxY <= this.wrapperPosition.y) {
        this.wrapperPosition.y = this.wrapperPosition.maxY;
      }
      if (this.wrapperPosition.minY >= this.wrapperPosition.y) {
        this.wrapperPosition.y = this.wrapperPosition.minY;
      }
      this.startPositionX = e.x;
      this.startPositionY = e.y;
    },
  },
};
</script>
